import Dropdown, { DropdownItem } from 'components/Dropdown/Dropdown';
import FormGroupInput from 'components/Form/FormGroupInput/FormGroupInput';
import Typography from 'components/Typography/Typography';
import config from 'config';
import { GOOGLE_PLACE_FORM_FIELDS_NAME } from 'constants/checkout';
import { CountryCode } from 'constants/store';
import useGoogleMapsApi from 'hooks/useGoogleMapsApi';
import usePrevious from 'hooks/usePrevious';
import { ChangeEvent, ReactNode, useEffect } from 'react';
import { Address, Country } from 'types/Address';
import usePlacesAutocomplete, { getGeocode, getLatLng, Suggestion } from 'use-places-autocomplete';
import { cn } from 'utils/cn';
import { log } from 'utils/loggerUtil';

const { defaultCountry } = config;

const STREET_DROPDOWN_ID = 'street-dropdown';

interface GoogleMapsStreetInputProps {
  appendedContent?: ReactNode;
  autoFill?: boolean;
  className?: string;
  country?: Country;
  disabled?: boolean;
  handleAutoFillFields?: (props: Address) => void;
  id?: string;
  label?: string;
  name: string;
}

const GoogleMapsStreetInput = ({
  appendedContent,
  autoFill = true,
  className = '',
  country = { isocode: defaultCountry as CountryCode },
  disabled,
  handleAutoFillFields,
  id,
  label,
  name,
}: GoogleMapsStreetInputProps) => {
  const countryPrev = usePrevious(country);

  const {
    clearCache,
    clearSuggestions,
    init,
    setValue: setValueHook,
    suggestions,
    value,
  } = usePlacesAutocomplete({
    debounce: 300,
    initOnMount: false,
    requestOptions: {
      componentRestrictions: { country: country?.isocode?.toUpperCase() ?? null },
    },
  });

  const { isLoaded } = useGoogleMapsApi();

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    // Update the keyword of the input element
    setValueHook(e.target.value);
  };

  // Find the value in the address_component object from the api result by passing the address component and the type
  const getAddressValueFromResult = (
    addressObject: google.maps.GeocoderAddressComponent[],
    type: string,
    shortName = true,
  ) => {
    const componentValue = shortName ? 'short_name' : 'long_name';
    return addressObject?.find((component) => component?.types.includes(type))?.[componentValue];
  };

  // Get the results and fill in fields
  const autoCompleteFields = (description: string) => {
    getGeocode({ address: description }).then((results) => {
      try {
        const addressComponent = results[0].address_components;
        const formattedAddress = results[0].formatted_address;
        const { lat, lng } = getLatLng(results[0]);

        const street = getAddressValueFromResult(addressComponent, GOOGLE_PLACE_FORM_FIELDS_NAME.ROUTE);

        const streetNumber = getAddressValueFromResult(addressComponent, GOOGLE_PLACE_FORM_FIELDS_NAME.STREET_NUMBER);

        const countryCode = getAddressValueFromResult(addressComponent, GOOGLE_PLACE_FORM_FIELDS_NAME.COUNTRY) as
          | CountryCode
          | undefined;

        const town = getAddressValueFromResult(addressComponent, GOOGLE_PLACE_FORM_FIELDS_NAME.LOCALITY);

        const zipCode = getAddressValueFromResult(addressComponent, GOOGLE_PLACE_FORM_FIELDS_NAME.POSTAL_CODE);

        handleAutoFillFields?.({
          countryCode,
          formattedAddress,
          latitude: lat,
          longitude: lng,
          street,
          streetNumber,
          town,
          zipCode,
        });
      } catch (err) {
        log(`Error when getting the geocode ${err}`);
      }
    });
  };

  const handleSelect = (suggestion: Suggestion) => {
    const { description } = suggestion;
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValueHook(description, false);
    autoCompleteFields(description);
    clearSuggestions();
  };

  // Clear when country changes so that no suggestions from the previous country is going to be shown
  useEffect(() => {
    if (countryPrev?.isocode !== country?.isocode) {
      clearSuggestions();
      clearCache();
    }
  }, [country]);

  useEffect(() => {
    if (autoFill && isLoaded) {
      init();
    }
  }, [autoFill, isLoaded]);

  return (
    <Dropdown
      className={cn(className, '!z-[12] [&_.dropdown-item-active]:bg-primary-40 [&_.dropdown-item-active]:text-white')}
      id={`${STREET_DROPDOWN_ID}_${id}`}
      items={suggestions?.data?.map((suggestion) => {
        const {
          place_id: placeId,
          structured_formatting: { main_text: address, secondary_text: city },
        } = suggestion;

        const dropdownItem: DropdownItem<Suggestion> = {
          node: (
            <div className="p-4" key={placeId} role="button" tabIndex={0}>
              <Typography color="" fontSize={100} fontWeight="bold">
                {address}
              </Typography>{' '}
              <Typography color="" fontSize={75}>
                {city}
              </Typography>
            </div>
          ),
          value: suggestion,
        };
        return dropdownItem;
      })}
      onCloseDropdownCallback={() => clearSuggestions()}
      onSubmitValue={(val) => handleSelect(val as Suggestion)}
      openOnClick={false}
      parent={
        <FormGroupInput
          appendedContent={appendedContent}
          disabled={disabled}
          handleChange={handleInput}
          id={id}
          label={label}
          name={name}
          type="text"
          value={value}
        />
      }
    />
  );
};

export default GoogleMapsStreetInput;
