import Flex from 'components/Flex/Flex';
import { Popup } from 'components/Layout';
import Typography from 'components/Typography/Typography';
import { ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import {
  StyledAnswer,
  StyledAnswersWrapper,
  StyledIndicator,
  StyledInput,
  StyledToggleSwitchLabel,
  StyledToggleSwitchWrap,
  StyledToggleWrap,
} from './ToggleSwitch.styled';

interface ToggleSwitchProps {
  className?: string;
  disabled?: boolean;
  hide?: boolean;
  id?: string;
  infoDescription?: string;
  infoTitle?: string;
  label?: string;
  name: string;
  noKey?: string;
  onClick?: (e: ChangeEvent<HTMLInputElement>) => void;
  title?: string;
  yesKey?: string;
}

const ToggleSwitch = ({
  className,
  disabled,
  hide,
  id,
  infoDescription,
  infoTitle,
  label,
  name,
  noKey = 'toggle_switch_no',
  onClick,
  title,
  yesKey = 'toggle_switch_yes',
}: ToggleSwitchProps) => {
  const { formatMessage } = useIntl();

  if (hide) return null;

  return (
    <StyledToggleWrap className={className}>
      {title && (
        <Typography fontWeight="normal" marginBottom={0} tag="h2" type="h3">
          <Flex flexDirection="row" gap={50}>
            {title}
            {infoDescription && infoTitle && <Popup description={infoDescription} title={infoTitle} />}
          </Flex>
        </Typography>
      )}

      <StyledToggleSwitchWrap>
        {label && <label htmlFor={id}>{label}</label>}

        <StyledToggleSwitchLabel htmlFor={id}>
          <StyledInput component="input" disabled={disabled} id={id} name={name} onClick={onClick} type="checkbox" />

          <StyledIndicator />

          <StyledAnswersWrapper>
            <StyledAnswer>{formatMessage({ id: yesKey })}</StyledAnswer>
            <StyledAnswer>{formatMessage({ id: noKey })}</StyledAnswer>
          </StyledAnswersWrapper>
        </StyledToggleSwitchLabel>
      </StyledToggleSwitchWrap>
    </StyledToggleWrap>
  );
};

export default ToggleSwitch;
