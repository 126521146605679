import { useState } from 'react';
import { useIntl } from 'react-intl';
import { ALERT_TYPES } from '../../../constants/alerts';
import Flex from '../../Flex/Flex';
import Alert from '../../Layout/Alert/Alert';
import Box from '../../Box/Box';
import { StyledFacebookButton, StyledGoogleButton } from './SocialLogins.styled';
import { useWebConfig } from 'features/configuration/queries';

const SocialLogins = () => {
  const { formatMessage } = useIntl();

  const { data: webConfig } = useWebConfig();

  const [error] = useState(false);

  return (
    <Box paddingBottom={200}>
      {error && <Alert type={ALERT_TYPES.DANGER}>{formatMessage({ id: 'social_login_error' })}</Alert>}

      <Flex alignItems="center" gap={200} justifyContent="center">
        {webConfig?.enableFacebookLogin && <StyledFacebookButton />}

        {webConfig?.enableGoogleLogin && <StyledGoogleButton />}

        {/* {webConfig?.enableAppleLogin && isAppleDevice && (
          <StyledAppleButton
            enableAppleLogin={webConfig?.enableAppleLogin}
            onAppleLoginError={onAppleLoginError}
            onAppleResponse={onAppleResponse}
          />
        )} */}
      </Flex>
    </Box>
  );
};

export default SocialLogins;
