export const ERROR_CODES = {
  COUPON_SERVICE_ERROR: 'CouponServiceError',
  DUPLICATE_UID_ERROR: 'DuplicateUidError',
  LOGIN_ERROR: 'LoginError',
  PASSWORD_ERROR: 'PasswordError',
  VALIDATION_ERROR: 'ValidationError',
  VOUCHER_ERROR: 'VoucherOperationError',
} as const;

export type ErrorCode = (typeof ERROR_CODES)[keyof typeof ERROR_CODES];

export const ERROR_SUBJECTS = {
  PASSWORD: 'password',
  VAT: 'vatId',
} as const;

export type ErrorSubject = (typeof ERROR_SUBJECTS)[keyof typeof ERROR_SUBJECTS];

export const ERROR_MESSAGES = {
  CART_INVALID_COUPON_CODE: 'coupon.invalid.code.provided',
  CART_VOUCHER_GIFTCARD_CODE: 'Code_giftcard',
} as const;

export type ErrorMessage = (typeof ERROR_MESSAGES)[keyof typeof ERROR_MESSAGES];
