import { useIntl } from 'react-intl';
import { Error, isValidationError } from 'types/Error';
import { ERROR_CODES, ERROR_MESSAGES, ERROR_SUBJECTS, ErrorCode, ErrorMessage } from '../constants/errors';

export const useErrorMessages = () => {
  const { formatMessage } = useIntl();

  const errorMessages: Partial<Record<ErrorCode | ErrorMessage, string>> = {
    [ERROR_CODES.DUPLICATE_UID_ERROR]: formatMessage({
      id: 'register_form_error_email_in_use',
    }),
    [ERROR_CODES.PASSWORD_ERROR]: formatMessage({
      id: 'registration_password_between_six_and_twenty_characters',
    }),
    [ERROR_CODES.LOGIN_ERROR]: formatMessage({
      id: 'login_error_wrong_credentials',
    }),
    [ERROR_MESSAGES.CART_VOUCHER_GIFTCARD_CODE]: formatMessage({
      id: 'cart_voucher_error_message_code_giftcard',
    }),
    [ERROR_MESSAGES.CART_INVALID_COUPON_CODE]: formatMessage({
      id: 'cart_promotions_invalid_code',
    }),
  };

  const getErrorMessage = (error: Error) => {
    const errorType = error?.type;

    if (isValidationError(error)) {
      switch (error.subject) {
        case ERROR_SUBJECTS.PASSWORD:
          return formatMessage({
            id: 'registration_password_between_six_and_twenty_characters',
          });
        case ERROR_SUBJECTS.VAT:
          return formatMessage({
            id: 'register_form_invalid_vat',
          });
        default:
          return error?.message;
      }
    }

    return (
      errorMessages[errorType] ??
      (error.message && errorMessages[error.message]) ??
      (error.message &&
        formatMessage({
          id: error.message,
        }))
    );
  };

  return { getErrorMessage };
};
