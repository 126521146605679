import { SOCIAL_TYPES } from '../../../constants/oauth';
import useSocialLogin from '../../../hooks/useSocialLogin';
import Typography from '../../Typography/Typography';
import { StyledGoogleIcon } from './GoogleButton.styled';

interface GoogleButtonProps {
  className?: string;
  text?: string;
}

const GoogleButton = ({ className, text }: GoogleButtonProps) => {
  const { handleSocialLogin } = useSocialLogin();

  return (
    <button className={className} onClick={() => handleSocialLogin(SOCIAL_TYPES.GOOGLE)} type="button">
      <StyledGoogleIcon />
      {text && <Typography>{text}</Typography>}
    </button>
  );
};

export default GoogleButton;
