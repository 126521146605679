import { Field } from 'react-final-form';
import styled from 'styled-components';
import { color, fontSize, spacer, variable } from 'theme';

export const StyledToggleWrap = styled.div`
  margin: ${spacer(150)} 0px;
`;

export const StyledToggleSwitchWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${spacer(50)};
`;

export const StyledInput = styled(Field)`
  position: absolute;
  opacity: 0;
  z-index: 5;
`;

export const StyledToggleSwitchLabel = styled.label`
  display: inline-block;
  position: relative;
  overflow: visible;
  padding: 0px;
  ${variable('border')};
  border-radius: ${variable('border-radius')};
  cursor: pointer;
  min-width: 110px;
  background-color: white;
  height: ${spacer(250)};
`;

export const StyledIndicator = styled.div`
  display: block;
  position: absolute;
  top: 3px;
  bottom: 3px;
  left: calc(50% - 3px);
  right: 3px;
  padding: 0px;
  z-index: 4;
  width: 50%;
  background-color: ${color('whisper-400')};
  border-radius: ${variable('border-radius')};
  transition: all 200ms ease;

  ${StyledInput}:focus ~ &,
  ${StyledInput}:active ~ & {
    outline: none;
    border: ${variable('border-darker')};
  }

  ${StyledInput}:checked ~ & {
    left: 3px;
    right: 50%;
    background-color: ${color('primary-300')};
  }
`;

export const StyledAnswer = styled.span`
  display: block;
  z-index: 5;
  width: 50%;
  margin-left: 50px;
  font-size: ${fontSize(100)};
  opacity: 1;
  text-align: center;
  line-height: 34px;
  font-weight: 400;

  &:first-of-type {
    color: ${color('black')};
    opacity: 1;
    left: 0;
    margin: 0;
    width: 50%;
  }

  &:last-of-type {
    left: auto;
    right: 0;
    color: ${color('white')};
    margin: 0;
    width: 50%;
  }
`;

export const StyledAnswersWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  vertical-align: middle;
  position: absolute;
  left: 0px;
  height: 100%;
  width: calc(100% - 6px);
  line-height: 20px;
  margin: 0px 3px;
  user-select: none;
  white-space: nowrap;

  ${StyledInput}:checked ~ & {
    ${StyledAnswer}:first-of-type {
      left: 0;
      color: ${color('white')};
    }

    ${StyledAnswer}:last-of-type {
      color: ${color('black')};
    }
  }
`;
