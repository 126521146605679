import { RegisterValidationSchemaType } from 'components/Register/RegisterForm/RegisterFormValidationSchema';
import config from 'config';
import useRouter from 'hooks/useRouter';
import { Url } from 'next/dist/shared/lib/router/router';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuthActions } from 'store/auth';
import { Error } from 'types/Error';
import { destructurePostalCode } from 'utils/postalCodeUtil';
import { register } from '../redux/modules/user';
import { useErrorMessages } from './useErrorMessages';

type OnRegisterParams = {
  handleError?: (error: Error) => void;
  isCheckout?: boolean;
  redirectUrl?: Url;
  registerFormValues?: RegisterValidationSchemaType;
  toggleFlyover?: () => void;
};

const useRegisterUser = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { getErrorMessage: getErrorMessages } = useErrorMessages();
  const { login } = useAuthActions();

  const [isRegistering, setIsRegistering] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const onRegister = async ({ isCheckout, redirectUrl, registerFormValues, toggleFlyover }: OnRegisterParams) => {
    const location = destructurePostalCode(registerFormValues?.invoice_location);

    const email = registerFormValues?.email || '';
    const password = registerFormValues?.password;
    const firstName = registerFormValues?.firstName;
    const lastName = registerFormValues?.lastName;
    const companyName = registerFormValues?.companyName;
    const companyVat = registerFormValues?.companyVat;
    const companyStreetName = registerFormValues?.invoice_route;
    const companyStreetNumber = registerFormValues?.invoice_street_number;
    const companyPobox = registerFormValues?.invoice_poBox;
    const phone = registerFormValues?.phone;

    const companyPostalCode = location?.postalCode;
    const companyTown = location?.name;
    const companyCountry = registerFormValues?.invoice_country?.isocode || config.defaultCountry;

    setIsRegistering(true);

    const loginCredentials = { email: email.toLowerCase(), password };
    const dto = {
      companyCountry,
      companyName,
      companyPobox,
      companyPostalCode,
      companyStreetName,
      companyStreetNumber,
      companyTown,
      firstName,
      lastName,
      password,
      passwordConfirmation: password,
      phone,
      uid: email.toLowerCase(),
      vatId: companyVat,
    };

    try {
      await dispatch(register(dto, false, false, false));
      await login(loginCredentials, undefined, !isCheckout);
      if (toggleFlyover) {
        toggleFlyover();
      }
      if (redirectUrl) {
        router.push(redirectUrl);
      }
    } catch (err) {
      const assertedErrors = err as { data: { errors: Error[] } };
      if (assertedErrors?.data?.errors?.length > 0) {
        const errorMessages = assertedErrors.data.errors.reduce((acc: string[], e) => {
          const errorMessage = getErrorMessages(e);
          if (errorMessage) {
            acc.push(errorMessage);
          }
          return acc;
        }, []);
        setErrorMessages(errorMessages);
      }
      setIsRegistering(false);
    }
  };

  return {
    errorMessages,
    isRegistering,
    onRegister,
    setIsRegistering,
  };
};

export default useRegisterUser;
