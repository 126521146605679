import { ErrorCode, ErrorMessage, ErrorSubject } from 'constants/errors';

export type Error = {
  message?: ErrorMessage;
  type: ErrorCode;
};

export type ValidationError = Error & {
  subject?: ErrorSubject;
  type: 'ValidationError';
};

export const isValidationError = (error: Error): error is ValidationError => error.type === 'ValidationError';
