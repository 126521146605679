import * as yup from 'yup';
import { REGISTER_FORM_FIELDS } from '../../../constants/authentication';
import {
  booleanValidation,
  companyNameValidation,
  emailValidation,
  firstNameValidation,
  lastNameValidation,
  phoneValidation,
  poBoxValidation,
  postalCodeCityValidation,
  registerPasswordValidation,
  routeValidation,
  streetNumberValidation,
  vatValidation,
} from '../../../utils/validation';

const ValidationSchema = {
  [REGISTER_FORM_FIELDS.EMAIL.name]: emailValidation(),
  [REGISTER_FORM_FIELDS.FIRST_NAME.name]: firstNameValidation(),
  [REGISTER_FORM_FIELDS.LAST_NAME.name]: lastNameValidation(),
  [REGISTER_FORM_FIELDS.PASSWORD.name]: registerPasswordValidation(),

  [REGISTER_FORM_FIELDS.TERMS_CONDITIONS.name]: booleanValidation(),

  [REGISTER_FORM_FIELDS.COMPANY_NAME.name]: yup.string().when([REGISTER_FORM_FIELDS.IS_B2B_CUSTOMER.name], {
    is: true,
    then: companyNameValidation(),
  }),
  [REGISTER_FORM_FIELDS.COMPANY_VAT.name]: yup.string().when([REGISTER_FORM_FIELDS.IS_B2B_CUSTOMER.name], {
    is: true,
    then: vatValidation(),
  }),
  [REGISTER_FORM_FIELDS.INVOICE_ROUTE.name]: yup.string().when([REGISTER_FORM_FIELDS.IS_B2B_CUSTOMER.name], {
    is: true,
    then: routeValidation(),
  }),
  [REGISTER_FORM_FIELDS.INVOICE_STREET_NUMBER.name]: yup.string().when([REGISTER_FORM_FIELDS.IS_B2B_CUSTOMER.name], {
    is: true,
    then: streetNumberValidation(),
  }),
  [REGISTER_FORM_FIELDS.INVOICE_LOCATION.name]: yup.string().when([REGISTER_FORM_FIELDS.IS_B2B_CUSTOMER.name], {
    is: true,
    then: postalCodeCityValidation(),
  }),
  [REGISTER_FORM_FIELDS.INVOICE_POBOX.name]: yup.string().when([REGISTER_FORM_FIELDS.IS_B2B_CUSTOMER.name], {
    is: true,
    then: poBoxValidation(true),
  }),
  [REGISTER_FORM_FIELDS.PHONE.name]: yup.string().when([REGISTER_FORM_FIELDS.IS_B2B_CUSTOMER.name], {
    is: true,
    then: phoneValidation(),
  }),
};

const registerValidationSchema = yup.object().shape(ValidationSchema);

export type RegisterValidationSchemaType = yup.InferType<typeof registerValidationSchema>;

export default ValidationSchema;
