import { useIntl } from 'react-intl';
import FormValidationSettings from '../../../../constants/formValidation';
import { ICON_NAMES } from '../../../../constants/icon';
import Flex from '../../../Flex/Flex';
import Icon from '../../../Icon/Icon';
import CheckListItem from '../../../Layout/CheckList/CheckListItem/CheckListItem';
import { StyledChecklist } from './PasswordRequirements.styled';

const {
  password: {
    length: { max, min },
  },
} = FormValidationSettings.validation;

type PassWordItemProps = { labelId: string; valid: boolean };

const PassWordItem = ({ labelId, valid }: PassWordItemProps) => {
  const { formatMessage } = useIntl();

  return (
    <CheckListItem>
      <Flex alignItems="center" gap={75}>
        <Icon color={valid ? 'ocean-400' : 'love-300'} name={valid ? ICON_NAMES.CHECK : ICON_NAMES.TIMES} size={75} />
        {formatMessage({ id: labelId })}
      </Flex>
    </CheckListItem>
  );
};

type PasswordRequirementsProps = { className?: string; password: string };

const PasswordRequirements = ({ className, password }: PasswordRequirementsProps) => {
  const minMaxLength = password?.length >= min && password.length <= max;
  const hasNumbersAndLetters = /^(?=.*[0-9])(?=.*[a-zA-Z])/.test(password);
  const containsCapital = /^(?=.*[A-Z])/.test(password);

  return (
    <StyledChecklist className={className}>
      <PassWordItem labelId="register_form_password_required_min" valid={minMaxLength} />
      <PassWordItem labelId="register_form_password_required_numbers_letters" valid={hasNumbersAndLetters} />
      <PassWordItem labelId="register_form_password_required_capital_letter" valid={containsCapital} />
    </StyledChecklist>
  );
};

export default PasswordRequirements;
