import { SocialLoginType } from 'constants/oauth';
import { useSession, signIn } from 'next-auth/react';
import { log } from 'utils/loggerUtil';

const useSocialLogin = () => {
  const nextAuthSession = useSession();
  const { data: session } = nextAuthSession;

  const handleSocialLogin = async (socialType: SocialLoginType) => {
    if (!session && socialType) {
      try {
        await signIn(socialType);
      } catch (error) {
        log('handleSocialLogin', 'Error occurred when signing in:', error);
      }
    }
  };

  return { handleSocialLogin, nextAuthSession };
};

export default useSocialLogin;
