import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface GoogleMapsApiState {
  loaded: boolean;
}

export interface GoogleMapsApiStore extends GoogleMapsApiState {
  actions: {
    setLoaded: (loaded: boolean) => void;
  };
}

const initialState: GoogleMapsApiState = {
  loaded: false,
};

export const useGoogleMapsApiStore = create<GoogleMapsApiStore>()(
  devtools(
    (set) => ({
      ...initialState,
      actions: {
        setLoaded: (loaded) => set(() => ({ loaded })),
      },
    }),
    { name: 'googleMapsApiStore' }
  )
);

export const useGoogleMapsApiLoaded = () => useGoogleMapsApiStore((state) => state.loaded);
export const useGoogleMapsApiActions = () => useGoogleMapsApiStore((state) => state.actions);
