import { Text } from 'ui/Text/Text';
import { ICON_NAMES, ICON_STYLES } from '../../../constants/icon';
import { SOCIAL_TYPES } from '../../../constants/oauth';
import useSocialLogin from '../../../hooks/useSocialLogin';
import Icon from '../../Icon/Icon';

interface AppleButtonProps {
  className?: string;
  text?: string;
}

const AppleButton = ({ className, text }: AppleButtonProps) => {
  const { handleSocialLogin } = useSocialLogin();

  return (
    <button
      className={className}
      onClick={() => {
        handleSocialLogin(SOCIAL_TYPES.APPLE);
      }}
      type="button"
    >
      <div className="flex flex-col items-center">
        <Icon iconStyling={ICON_STYLES.BRAND} name={ICON_NAMES.APPLE} size={125} />
        {text && <Text>{text}</Text>}
      </div>
    </button>
  );
};

export default AppleButton;
