import styled, { css } from 'styled-components';
import { spacer, variable, color } from 'theme';
import AppleButton from '../../Social/AppleButton/AppleButton';
import FacebookButton from '../../Social/FacebookButton/FacebookButton';
import GoogleButton from '../../Social/GoogleButton/GoogleButton';

const socialLoginButtonStyling = css`
  ${variable('border-thick')}
  border-radius: ${variable('border-radius')};
  transition: border-color 0.3s ease;
  padding: 0px;
  width: ${spacer(400)};
  height: ${spacer(400)};
  background-color: ${color('white')};

  &:hover {
    border-color: ${color('whisper-100')};
  }
`;

export const StyledFacebookButton = styled(FacebookButton)`
  ${socialLoginButtonStyling}
`;

export const StyledGoogleButton = styled(GoogleButton)`
  ${socialLoginButtonStyling}
`;

export const StyledAppleButton = styled(AppleButton)`
  ${socialLoginButtonStyling}
`;
