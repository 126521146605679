import { Loader } from '@googlemaps/js-api-loader';
import { Libraries } from '@react-google-maps/api';
import useRouter from 'hooks/useRouter';
import { useCallback, useEffect } from 'react';
import { useGoogleMapsApiActions, useGoogleMapsApiLoaded } from 'store/googleMapsApi';
import { log } from 'utils/loggerUtil';

const libraries: Libraries = ['places'];

const useGoogleMapsApi = () => {
  const router = useRouter();
  const { locale } = router;
  const loaded = useGoogleMapsApiLoaded();
  const { setLoaded } = useGoogleMapsApiActions();

  const initMapsApi = useCallback(async () => {
    try {
      const mapsApiLoader = new Loader({
        apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? '',
        id: `google-maps-api`,
        language: locale,
        libraries,
        version: 'quarterly',
      });
      await mapsApiLoader.importLibrary('core').then(() => {
        setLoaded(true);
      });
    } catch (error) {
      log('useGoogleMapsApi', 'Loading maps api failed', error);
    }
  }, []);

  useEffect(() => {
    if (!loaded) {
      initMapsApi();
    }
  }, []);

  return { isLoaded: loaded };
};

export default useGoogleMapsApi;
