const getFieldId = (formId: string, fieldName: string) => `${formId}_${fieldName}`;

export const LOGIN_FORM_ID = 'LoginForm';
export const REGISTER_FORM_ID = 'register-form';

export const LOGIN_FORM_FIELDS = {
  EMAIL: {
    id: getFieldId(LOGIN_FORM_ID, 'email'),
    label: 'login_form_email',
    name: 'email',
    type: 'email',
  },
  PASSWORD: {
    id: getFieldId(LOGIN_FORM_ID, 'password'),
    label: 'login_form_password',
    name: 'password',
    type: 'password',
  },
};

export const REGISTER_FORM_FIELDS = {
  COMPANY_NAME: {
    id: getFieldId(REGISTER_FORM_ID, 'companyName'),
    label: 'register_form_companyName',
    name: 'companyName',
  },
  COMPANY_VAT: { id: getFieldId(REGISTER_FORM_ID, 'companyVat'), label: 'register_form_vatId', name: 'companyVat' },
  EMAIL: { id: getFieldId(REGISTER_FORM_ID, 'email'), label: 'register_form_email', name: 'email', type: 'email' },
  FIRST_NAME: {
    id: getFieldId(REGISTER_FORM_ID, 'firstName'),
    label: 'register_form_firstname',
    name: 'firstName',
  },
  INVOICE_COUNTRY: { name: 'invoice_country' },
  INVOICE_LOCATION: { name: 'invoice_location' },
  INVOICE_PHONE: { name: 'invoice_phone' },
  INVOICE_POBOX: { name: 'invoice_poBox' },
  INVOICE_POSTALCODE: { name: 'invoice_postalcode' },
  INVOICE_ROUTE: { name: 'invoice_route' },
  INVOICE_STREET_NUMBER: { name: 'invoice_street_number' },
  IS_B2B_CUSTOMER: {
    id: getFieldId(REGISTER_FORM_ID, 'isB2BCustomer'),
    label: 'register_form_b2b',
    name: 'isB2BCustomer',
  },
  LAST_NAME: {
    id: getFieldId(REGISTER_FORM_ID, 'lastName'),
    label: 'register_form_lastname',
    name: 'lastName',
  },
  PASSWORD: { id: getFieldId(REGISTER_FORM_ID, 'password'), label: 'login_form_password', name: 'password' },
  PHONE: {
    id: getFieldId(REGISTER_FORM_ID, 'phone'),
    label: 'register_form_phone',
    name: 'phone',
  },
  TERMS_CONDITIONS: { name: 'terms_and_conditions' },
};
